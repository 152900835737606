<template>
  <div class="sale-confirmation">
    <div class="container-xl">
      <div class="card mb-3">
        <div v-if="!confirmation.uuid" class="text-center p-5"><i>Загрузка...</i></div>
        <div v-if="confirmation.uuid" class="card-body">
          <div class="h6">№ {{ confirmation.agreementNumber }}</div>
          <iframe :src="confirmation.agreementUrl"></iframe>
          <form @input="validateForm" @change="validateForm" @submit="sell">
            <div class="row flex-column-reverse flex-lg-row mt-4">
              <div class="col-12 col-lg-8">
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input v-model="agreement" type="checkbox" class="custom-control-input" id="agreement" required>
                    <label class="custom-control-label" for="agreement"></label>
                    <public-link to="/page/pravila-vtorichnogo-rynka-investitsionnoi-platformy-lendly"
                                 target="_blank"
                    >Согласен с правилами вторичного рынка</public-link>
                  </div>
                  <input-errors :errors="errors.agreement"/>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <a :href="confirmation.agreementUrl" target="_blank">
                  Оферта о заключении договора и уступки прав требований (цессии) (скачать PDF)
                </a>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 col-md-6 mb-3 mb-md-0">
                <div class="mb-3">
                  <span class="badge badge-secondary">1</span>
                </div>
                <button @click="sendCode" class="btn btn-info" type="button">
                  Отправить SMS с кодом подтверждения
                </button>
                <div v-if="confirmation.phone" class="text-muted mt-2">
                  На номер: +{{ confirmation.phone.countryCode }} {{ confirmation.phone.number }}
                </div>
              </div>
              <div class="col-12 col-md-3 mb-4 mb-md-0">
                <div class="mb-3">
                  <span class="badge badge-secondary">2</span>
                </div>
                <input v-model="smsCode" ref="smsInput" class="form-control form-control-lg" maxlength="6" placeholder="Код из SMS" required>
                <input-errors :errors="errors.smsCode"/>
              </div>
              <div class="col-12 col-md-3">
                <div class="mb-3">
                  <span class="badge badge-secondary">3</span>
                </div>
                <button type="submit" class="btn btn-lg btn-success">
                  Подтвердить
                </button>
              </div>
            </div>
            <div class="mt-4">
              Подписание настоящей оферты осуществляется Принципалом и фиксируется в электронной форме с использованием информационных
              технологий и технических средств Площадки.
            </div>
          </form>
        </div>
      </div>
    </div>
    <processing :show="processing"/>
  </div>
</template>

<script>
import { InputErrors, Processing, PublicLink } from "@/components/common";
import { aggregateValidationErrors } from "@/utils";
import { ValidationError } from "@/utils/api-client";
import { createOfferSaleManager } from "@/utils/di";

export default {
  name: "SaleConfirmation",
  components: {
    InputErrors,
    Processing,
    PublicLink
  },
  data() {
    return {
      confirmation: {},
      agreement: false,
      smsCode: null,
      errors: {},
      processing: false
    };
  },
  async mounted() {
    const manager = await createOfferSaleManager();
    this.confirmation = await manager.getConfirmation(this.$route.params.confirmationUuid);
  },
  methods: {
    validateForm() {
      const errors = {};
      if (!this.agreement) {
        errors.agreement = ['Пожалуйста, отметьте это поле'];
      }
      if (!this.smsCode) {
        errors.smsCode = ['Пожалуйста, введите код из SMS'];
      }
      this.errors = errors;
      return Object.keys(errors).length === 0;
    },
    async sendCode() {
      this.processing = true;
      try {
        const manager = await createOfferSaleManager();
        await manager.sendConfirmationCode(this.confirmation.uuid);
      } finally {
        this.processing = false;
        this.$refs.smsInput.focus();
      }
    },
    async sell(e) {
      e.preventDefault();
      if (this.validateForm()) {
        this.processing = true;
        try {
          const manager = await createOfferSaleManager();
          await manager.create(this.confirmation.uuid, this.smsCode);
          await this.$router.push({ name: "myInvestments" });
        } catch (err) {
          if (err instanceof ValidationError) {
            this.$notifications.addAlert(err.message)
            this.errors = aggregateValidationErrors(err);
          } else {
            throw err;
          }
        } finally {
          this.processing = false;
        }
      }
    }
  }
}
</script>

<style lang="scss">
.sale-confirmation {
  iframe {
    width: 100%;
    height: 40rem;
  }

  .badge.badge-secondary {
    font-size: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.75rem;
  }
}
</style>
